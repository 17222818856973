import React, { useEffect, useState } from "react";
import Sidebar from "../../../CommonComponents/Sidebar/sidebar";
import Header from "../../../CommonComponents/Header/header";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import apis from "../../../CommonComponents/apis";
import { toast } from "react-toastify";
import InvoiceTable from "../../../CommonComponents/UI/InvoiceTable";
// import ReportsModal from "./Partials/ReportsModal";
const getFormDataUrl = "/invoice/getInvoiceData";
const token = localStorage.getItem("supplier_accessToken");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    permission: "",
  },
};
const Invoice = () => {
  const [loading, setLoading] = useState(false);
  const [getTableDataLoading, setGetTableDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { t, i18n } = useTranslation();
  // call to fetch data
  const fetchFormData = () => {
    // add permissions based on URL
    config.headers.permission = "reports-view";
    setGetTableDataLoading(true);
    apis
      .get(getFormDataUrl, config)
      //.get(getFormDataUrl)
      .then((res) => {
        if (res.status === 200) {
          console.log("Response Sales table data", { res });
          setTableData(res.data.data);
          setGetTableDataLoading(false);
        }
      })
      .catch((error) => {
        console.log({ error });
        setGetTableDataLoading(false);
        if (error) {
          console.log({ error });
        }
      });
    setGetTableDataLoading(false);
  };

  // uncomment to enable api call on loading
  useEffect(() => {
     fetchFormData();
   }, []);

  return (
    <>
      <div className="container-fluid page-wrap product-manage">
        <div className="row height-inherit">
          <Sidebar userType={"supplier"} />
          <div className="col main p-0">
        <Header title={t("supplier.invoice_title_list")} />
            <div className="container-fluid page-content-box px-3 px-sm-4">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                      <div className="col-sm-12">
                    <a href="/supplier/create_invoice">
   <Button variant="outline-secondary button-invoice">{t("supplier.invoice_create")}</Button>
   </a>
   <a href="/supplier/create_invoice_2f">
<Button variant="outline-secondary button-invoice">{t("supplier.invoice_create_2f")}</Button>
</a>

<a href="/supplier/create_invoice_3f">
<Button variant="outline-secondary button-invoice">{t("supplier.invoice_create_3f")}</Button>
</a>
   </div>
                        <div className="col-sm-12 md-12 mb-4 mb-sm-0">
     <hr />
                        <InvoiceTable
                         tableData={tableData}
headings={[t("supplier.invoice_customer"), t("supplier.invoice_number"), t("supplier.invoice_date"), t("supplier.due_date"), t("supplier.invoice_amount"), t("supplier.invoice_status"), t("supplier.invoice_action")]}
                          className=""
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
