export const ROLE_VIEW = "role-view";
export const ROLE_EDIT = "role-edit";
export const USER_VIEW = "user-view";
export const USER_EDIT = "user-edit";
export const RETAILER_VIEW = "retailer-view";
export const RETAILER_EDIT = "retailer-edit";
export const DISTRIBUTOR_VIEW = "distributor-view";
export const DISTRIBUTOR_EDIT = "distributor-edit";
export const SUPPLIER_VIEW = "supplier-view";
export const SUPPLIER_EDIT = "supplier-edit";
export const DASHBOARD_VIEW = "dashboard-view";
export const DASHBOARD_EDIT = "dashboard-edit";
export const ORDER_VIEW = "order-view";
export const ORDER_EDIT = "order-edit";
export const INVENTORY_VIEW = "inventory-view";
export const INVENTory_EDIT = "inventory-view";
export const INVENTORY_EDIT = "inventory-edit";
export const PRODUCT_VIEW = "product-view";
export const PRODUCT_EDIT = "product-edit";
export const ROUTES_VIEW = "routes-view";
export const ROUTES_EDIT = "routes-edit";
export const SHIPMENT_VIEW = "shipment-view";
export const SHIPMENT_EDIT = "shipment-view";
export const REPORTS_VIEW = "reports-view";
export const REPORTS_EDIT = "reports-edit";
export const MARKETPLACE_VIEW = "marketplace-view";
export const MARKETPLACE_EDIT = "marketplace-edit";
export const GROUP_VIEW = "groups-view";
export const GROUP_EDIT = "groups-edit";
export const PRICING_VIEW = "pricing-view";
export const PRICING_EDIT = "pricing-edit";
