import React, { useEffect, useState } from "react";
import InvoiceItem from "../InvoiceItem";

function Invoice_3f_Component({
  index,
  setFormData,
  addItemHandler,
  deleteItemHandler,
  edtiItemHandler,
  items,
  formData,
  componentRef,
  deleteComponent,
  dataOriginal,
  companyNameListOriginal,
  productNameListOriginal,
  orderIDListOriginal,
}) {
  const [data, setData] = useState([...dataOriginal]);

  const [companyNameList, setCompanyNameList] = useState([
    ...companyNameListOriginal,
  ]);
  const [productNameList, setProductNameList] = useState([
    ...productNameListOriginal,
  ]);
  const [orderIDList, setOrderIDList] = useState([...orderIDListOriginal]);

  useEffect(() => {
    setData([...dataOriginal]);
  }, [dataOriginal]);

  useEffect(() => {
    setCompanyNameList([...companyNameListOriginal]);
  }, [companyNameListOriginal]);

  useEffect(() => {
    setProductNameList([...productNameListOriginal]);
  }, [productNameListOriginal]);

  useEffect(() => {
    setOrderIDList([...orderIDListOriginal]);
  }, [orderIDListOriginal]);

  const getDataWithID = (Obj, ObjName, ObjId) => {
    return Obj[ObjName].map((name, i) => ({
      id: Obj[ObjId][i],
      value: name,
    }));
  };

  useEffect(() => {
    if (!!formData[`distributor_name`]) {
      const Obj = data?.find(
        ({ distributor_id }) => distributor_id == formData[`distributor_name`]
      );

      if (Obj) {
        setOrderIDList(Obj?.order_id);

        const retailerWithIDs = getDataWithID(
          Obj,
          "retailer_name",
          "retailer_id"
        );

        const productWithIds = getDataWithID(Obj, "product_name", "product_id");

        setCompanyNameList(retailerWithIDs);
        setProductNameList(productWithIds);
      }
    }
  }, [formData[`distributor_name`]]);

  useEffect(() => {
    if (!!formData[`retailer_name_${index}`]) {
      const Obj = data?.find(({ retailer_id }) =>
        retailer_id.includes(formData[`retailer_name_${index}`])
      );
      if (Obj) {
        const productWithIds = getDataWithID(Obj, "product_name", "product_id");

        setProductNameList(productWithIds);
        setOrderIDList(Obj?.order_id);
      }
    }
  }, [formData[`retailer_name_${index}`]]);

  useEffect(() => {
    if (!!formData[`order_id_${index}`]) {
      const Obj = data?.find(({ order_id }) =>
        order_id.includes(formData[`order_id_${index}`])
      );
      if (Obj) {
        const productWithIds = getDataWithID(Obj, "product_name", "product_id");

        const retailerWithIDs = getDataWithID(
          Obj,
          "retailer_name",
          "retailer_id"
        );

        setProductNameList(productWithIds);
        setCompanyNameList(retailerWithIDs);
      }
    }
  }, [formData[`order_id_${index}`]]);

  useEffect(() => {
    if (!!formData[`product_name_${index}`]) {
      const Obj = data?.find(({ product_name }) =>
        product_name.includes(formData[`product_name_${index}`])
      );
      if (Obj) {
        const retailerWithIDs = getDataWithID(
          Obj,
          "retailer_name",
          "retailer_id"
        );

        setOrderIDList(Obj?.order_id);
        setCompanyNameList(retailerWithIDs);
      }
    }
  }, [formData[`product_name_${index}`]]);

  return (
    <>
      {
        <div
          className="mb-2"
          ref={(element) => (componentRef.current[index] = element)}
        >
          <button
            type="button"
            className="d-block ms-auto rounded-md bg-red-500 p-2 text-white shadow-sm transition-colors duration-200 hover:bg-red-600"
            id={`delete_comp_${index}`}
            onClick={(event) => {
              const id = event.currentTarget.id;
              const idIndex = Number(id.split("delete_comp_invoice_")[1]);
              deleteComponent(idIndex);
            }}
          >
            <span className="d-flex">
              Delete Invoice{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </span>
          </button>
          <div className="grid grid-cols-2 gap-2  pb-4">
            <label
              htmlFor="cashierName"
              className="text-sm font-bold sm:text-base"
            >
              Produced by and sold n behalf of
            </label>
            <select
              required
              className="flex-1"
              placeholder="Customer name"
              type="text"
              name={`retailer_name_${index}`}
              id="product_name"
              value={formData[`retailer_name_${index}`]}
              onChange={(event) =>
                setFormData((prev) => ({
                  ...prev,
                  [`retailer_name_${index}`]: String(event.target.value),
                }))
              }
            >
              <option value="">Select...</option>
              {companyNameList.map((data, index) => {
                return (
                  <option key={index} value={data.id}>
                    {data.value}
                  </option>
                );
              })}
            </select>
            <label
              htmlFor="customerName"
              className="col-start-2 row-start-1 text-sm font-bold md:text-base"
            >
              Order ID
            </label>
            <select
              className="flex-1"
              name={`order_id_${index}`}
              id="order_id"
              value={formData[`order_id_${index}`]}
              onChange={(event) =>
                setFormData((prev) => ({
                  ...prev,
                  [`order_id_${index}`]: String(event.target.value),
                }))
              }
            >
              <option value="">Select...</option>
              {orderIDList.map((data, index) => {
                return (
                  <option key={index} value={data}>
                    {data}
                  </option>
                );
              })}
            </select>
          </div>
          <table className="w-full p-4 text-left">
            <thead>
              <tr className="border-b border-gray-900/10 text-sm md:text-base">
                <th>PRODUCTS</th>
                <th>QTY</th>
                <th className="text-center">PRICE</th>
                <th className="text-center">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {items[index]?.map((item, i) => (
                <InvoiceItem
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  qty={item.qty}
                  price={item.price}
                  onDeleteItem={deleteItemHandler}
                  onEdtiItem={(e) => edtiItemHandler(e, index)}
                  index={index}
                  options={
                    <>
                      <option value="">Select...</option>
                      {productNameList.map((data, index) => {
                        return (
                          <option key={index} value={data.value}>
                            {data.value}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              ))}
            </tbody>
          </table>
          <button
            className="mt-2 rounded-md bg-blue-500 px-4 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
            type="button"
            onClick={() => addItemHandler(index)}
          >
            Add Item
          </button>
        </div>
      }
      <hr />
    </>
  );
}

export default Invoice_3f_Component;
