import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../../CommonComponents/Sidebar/sidebar";
import Header from "../../../CommonComponents/Header/header";
import { useTranslation } from "react-i18next";
import apis from "../../../CommonComponents/apis";
import { toast } from "react-toastify";
import { uid } from "uid";
import "./index.css";
import Invoice_3f_Component from "./partials/Invoice_3f_Component";
import BillDetails from "./partials/BillDetails";
import Loader from "../UI/Loader";

const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});
const url = "/DistributorProductList";

const token = localStorage.getItem("supplier_accessToken");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    permission: "",
  },
};

const Invoice = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({});
  const [distributorNameList, setDistributorNameList] = useState([]);

  const [InvoiceComponents, setInvoiceComponents] = useState([
    { component: Invoice_3f_Component, id: 0 },
  ]);
  const [deletedComponent, setDeletedComponent] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [companyNameList, setCompanyNameList] = useState([]);
  const [productNameList, setProductNameList] = useState([]);
  const [orderIDList, setOrderIDList] = useState([]);

  const fetchData = async () => {
    await apis
      .get(url, config)
      .then((res) => {
        setLoading(false);

        if (res.data.success === true) {
          //   const data = res.data.data; // this is data from api ----- comment below data when using this

          const data = [
            {
              distributor_id: 103,
              distributor_name: "Distribution Bucké",
              retailer_id: [102, 103],
              retailer_name: ["Retai1", "Retai5"],
              order_id: [6, 8],
              product_id: [156, 157, 158],
              product_name: [
                "Bonne Aventure - Can 473ml x 24",
                "Cannes-de-Roches - Bottle ale 500ml x 12",
                "Caribou sure No9 - Can 473ml x 24",
              ],
            },
            {
              distributor_id: 105,
              distributor_name: "Distribution Bucké 2",
              retailer_id: [104, 105],
              retailer_name: ["Retai4", "Retai2"],
              order_id: [11],
              product_id: [102, 154, 155],
              product_name: [
                "Blanche de Pratto - Can 473ml x 24",
                "Blanche Lamontagne - Can 473ml x 24",
                "Blonde de l'Anse - Can 473ml x 24",
              ],
            },
            {
              distributor_id: 102,
              distributor_name: "Distribution Bucké 3",
              retailer_id: [102, 105],
              retailer_name: ["Retai1", "Retai2"],
              order_id: [9, 10, 11],
              product_id: [153, 154, 155, 156, 157],
              product_name: [
                "Blanche de Pratto - Can 473ml x 24",
                "Blanche Lamontagne - Can 473ml x 24",
                "Blonde de l'Anse - Can 473ml x 24",
                "Bonne Aventure - Can 473ml x 24",
                "Cannes-de-Roches - Bottle ale 500ml x 12",
              ],
            },
          ];
          setData(data);

          setDistributorNameList(() =>
            data.map((val) => ({
              id: val.distributor_id,
              value: val.distributor_name,
            }))
          );

          setCompanyNameList(() =>
            data.map((val) => ({
              id: val.retailer_id,
              value: val.retailer_name,
            }))
          );

          const uniqueOrderList = data.map((val) => val.order_id).flat();

          const productWithIds = data.flatMap((val) =>
            val.product_name.map((name, index) => ({
              id: val.product_id[index],
              value: name,
            }))
          );
          const uniqueProductNameList = new Map(
            productWithIds.map((product) => [product.id, product])
          );

          setOrderIDList([...new Set(uniqueOrderList)]);
          setProductNameList([...uniqueProductNameList.values()]);
        } else {
          toast.error("Something went wrong. Please try again later.", {
            autoClose: 2000,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.message !== "revoke") {
          toast.error("Something went wrong. Please try again later.", {
            autoClose: 2000,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [items, setItems] = useState({});
  const componentRef = useRef([]);

  const addItemHandler = (index) => {
    const id = uid(6);
    setItems((prevItem) => ({
      ...prevItem,
      [index]: prevItem[index]
        ? [
            ...prevItem[index],
            {
              id: id,
              name: "",
              qty: 1,
              price: "1.00",
            },
          ]
        : [
            {
              id: id,
              name: "",
              qty: 1,
              price: "1.00",
            },
          ],
    }));
  };

  const deleteItemHandler = (id, index) => {
    setItems((prevItem) => ({
      ...prevItem,
      [index]: prevItem[index]?.filter((item) => item.id !== id),
    }));
  };

  const edtiItemHandler = (event, index) => {
    const editedItem = {
      id: event.target.id,
      name: event.target.name,
      value: event.target.value,
    };

    const newItems = items[index]?.map((items) => {
      for (const key in items) {
        if (key === editedItem.name && items.id === editedItem.id) {
          items[key] = editedItem.value;
        }
      }
      return items;
    });

    // setItems(newItems);
    setItems((prev) => ({
      ...prev,
      [index]: newItems,
    }));
  };

  const addInvoiceComponentHandler = () => {
    setInvoiceComponents((prevItem) => [
      ...prevItem,
      {
        component: Invoice_3f_Component,
        id: !!prevItem?.length ? prevItem[prevItem.length - 1]?.id + 1 : 0,
      },
    ]);

    const addedIndex =
      "invoice_" + (InvoiceComponents[InvoiceComponents.length - 1]?.id + 1);
    setTimeout(() => {
      addedIndex != "invoice_0" &&
        componentRef?.current[addedIndex]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
    }, 500);
  };

  const transformData = (data) => {
    const invoices = [];

    for (let i = 0; i < Object.keys(data?.products).length; i++) {
      invoices.push({
        order_id: data[`order_id_invoice_${i}`],
        retailer_name: data[`retailer_name_invoice_${i}`],
        products: data.products[`invoice_${i}`],
      });
    }

    return {
      distributor_name: data.distributor_name,
      invoice_date: data.invoice_date,
      due_date: data.due_date,
      invoice_status: data.invoice_status,
      tax: data.tax,
      discount: data.discount,
      note: data.note,
      invoice: invoices,
    };
  };

  const reviewInvoiceHandler = (event) => {
    event.preventDefault();

    const data = new FormData(event.target); // Get form data
    const formObject = {};

    // Iterate over all entries in the FormData object
    data.forEach((value, key) => {
      formObject[key] = value;
    });

    delete formObject.name;
    delete formObject.qty;
    delete formObject.price;

    // const groupedData = {};

    // for (const key in formObject) {
    //   if (
    //     !key.startsWith("retailer_name_invoice_") &&
    //     !key.startsWith("order_id_invoice_") &&
    //     key !== "products"
    //   ) {
    //     groupedData[key] = formObject[key];
    //   }
    // }

    const oldItems = items;
    const oldIndex = !!deletedComponent && "invoice_" + deletedComponent[0]?.id;

    if (oldIndex && oldItems.hasOwnProperty(oldIndex)) {
      delete oldItems[oldIndex];
    }

    formObject.products = oldItems;

    const transformedData = transformData(formObject);

    console.log("transformedData ::: ", transformedData);

    // Object.keys(formObject.products).forEach((key, i) => {
    //   const indexFromKey = Number(key.split("invoice_")[1]);
    //   groupedData[key] = {
    //     [`retailer_name_invoice_${indexFromKey}`]:
    //       formObject[`retailer_name_invoice_${indexFromKey}`],
    //     [`order_id_invoice_${indexFromKey}`]:
    //       formObject[`order_id_invoice_${indexFromKey}`],
    //     [`products${indexFromKey}`]: {
    //       [key]: formObject.products[key],
    //     },
    //   };
    // });

    const postURL = "/invoice/CreateInvoiceData3F";
    const form = event.currentTarget;

    // form valid ?
    if (form.checkValidity() === true) {
      config.headers.permission = "reports-view";

      apis
        .post(postURL, transformedData, config)
        .then((res) => {
          console.log("response", { res });

          if (res.status === 200) {
            toast.success("Invoice Generated Successfully!", {
              autoClose: 1000,
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error(res.data.message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          console.log({ error });

          if (error) {
            toast.error("Error!.. creating invoice. Please try again later.", {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
      setDeletedComponent(null);
    }
  };

  const deleteComponent = (idIndex) => {
    const updatedComponents = [...InvoiceComponents];
    const removeBasedOnIndex = updatedComponents.findIndex(
      ({ id }) => id === idIndex
    );
    let deleted = [];

    if (removeBasedOnIndex !== -1) {
      deleted = updatedComponents.splice(removeBasedOnIndex, 1);
    }

    setInvoiceComponents(updatedComponents);
    setDeletedComponent(deleted);
  };

  return (
    <div className="container-fluid page-wrap product-manage invoiceclass">
      <div className="row height-inherit">
        <Sidebar userType={"supplier"} />
        <div className="col main p-0">
          <Header title={t("supplier.invoice_title_list")} />
          {!!loading && <Loader />}
          {!loading && (
            <div className="container-fluid page-content-box px-3 px-sm-4">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <form
                          className="relative flex flex-col px-2 md:flex-row"
                          onSubmit={reviewInvoiceHandler}
                        >
                          <div className="my-6 flex-1 space-y-2  rounded-md bg-white p-4 shadow-sm sm:space-y-4 md:p-6">
                            <h1 className="text-center text-lg font-bold">
                              INVOICE
                            </h1>
                            <div className="grid grid-cols-2 gap-2 pt-4 pb-4">
                              <label
                                htmlFor="cashierName"
                                className="text-sm font-bold sm:text-base"
                              >
                                Distributor
                              </label>
                              <select
                                required
                                className="flex-1"
                                placeholder="Customer name"
                                type="text"
                                name={`distributor_name`}
                                id="distributor_name"
                                value={formData[`distributor_name`]}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    [`distributor_name`]: String(
                                      event.target.value
                                    ),
                                  }))
                                }
                              >
                                <option value="">Select...</option>
                                {distributorNameList.map((data, index) => {
                                  return (
                                    <option key={index} value={data.id}>
                                      {data.value}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                htmlFor="customerName"
                                className="col-start-2 row-start-1 text-sm font-bold md:text-base"
                              >
                                Invoice Date
                              </label>
                              <input
                                required
                                className="flex-1"
                                //   placeholder="Invoice date"
                                type="date"
                                name={`invoice_date`}
                                id="invoice_date"
                                value={formData[`invoice_date`]}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    [`invoice_date`]: String(
                                      event.target.value
                                    ),
                                  }))
                                }
                              />
                            </div>

                            <div className="grid grid-cols-2 gap-2 pb-4">
                              <label
                                htmlFor="cashierName"
                                className="text-sm font-bold sm:text-base"
                              >
                                Due Date
                              </label>
                              <input
                                required
                                className="flex-1"
                                //   placeholder="Invoice date"
                                type="date"
                                name={`due_date`}
                                id="due_date"
                                value={formData[`due_date`]}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    [`due_date`]: String(event.target.value),
                                  }))
                                }
                              />
                              <label
                                htmlFor="customerName"
                                className="col-start-2 row-start-1 text-sm font-bold md:text-base"
                              >
                                Invoice Status
                              </label>
                              <select
                                className="flex-1"
                                name={`invoice_status`}
                                id="invoice_status"
                                value={formData[`invoice_status`]}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    [`invoice_status`]: String(
                                      event.target.value
                                    ),
                                  }))
                                }
                              >
                                <option value="">Select...</option>
                                <option value="0">Pending</option>
                                <option value="1">Paid</option>
                                <option value="2">Unpaid</option>
                              </select>
                            </div>
                            {InvoiceComponents?.map(
                              ({ component: Invoice, id }, index) => {
                                return (
                                  <Invoice
                                    index={"invoice_" + id}
                                    setFormData={setFormData}
                                    addItemHandler={addItemHandler}
                                    edtiItemHandler={edtiItemHandler}
                                    deleteItemHandler={deleteItemHandler}
                                    items={items}
                                    formData={formData}
                                    config={config}
                                    componentRef={componentRef}
                                    distributorNameList={distributorNameList}
                                    setDistributorNameList={
                                      setDistributorNameList
                                    }
                                    deleteComponent={deleteComponent}
                                    dataOriginal={data}
                                    companyNameListOriginal={companyNameList}
                                    productNameListOriginal={productNameList}
                                    orderIDListOriginal={orderIDList}
                                  />
                                );
                              }
                            )}

                            <button
                              className="mt-4 w-full rounded-md bg-blue-500 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
                              type="button"
                              onClick={addInvoiceComponentHandler}
                            >
                              Add New Invoice
                            </button>

                            <BillDetails
                              formData={formData}
                              setFormData={setFormData}
                              items={items}
                            />
                            <br />
                            <button
                              className="w-full rounded-md bg-blue-500 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
                              type="submit"
                            >
                              Create Invoice
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
