import React, { useEffect, useState } from "react";
import Sidebar from "../../../CommonComponents/Sidebar/sidebar";
import Header from "../../../CommonComponents/Header/header";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import apis from "../../../CommonComponents/apis";
import { toast } from "react-toastify";
import { uid } from "uid";
import InvoiceItem from "./InvoiceItem";
import InvoiceModal from "./InvoiceModal";
import incrementString from "./helpers/incrementString";
import "./index.css";
import Loader from "../UI/Loader";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
const date = new Date();
const today = date.toLocaleDateString("en-GB", {
  month: "numeric",
  day: "numeric",
  year: "numeric",
});

const token = localStorage.getItem("supplier_accessToken");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    permission: "",
  },
};
const url = "/RetailersProductList_new";
const getTaxListData = "/getTaxes";

const Invoice = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [fullTaxListData, setTaxListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [formData, setFormData] = useState({
    company_name: "",
    order_id: "",
    invoice_date: "",
    due_date: "",
    status: "",
    tax: "",
    discount: "",
    language: i18n.language,
    note: "",
  });

  const [companyNameList, setCompanyNameList] = useState([]);
  const [productNameList, setProductNameList] = useState([]);
  const [orderIDList, setOrderIDList] = useState([]);

  const [items, setItems] = useState([
    {
      id: uid(6),
      name: "",
      qty: 1,
      price: "1.00",
    },
  ]);
  const fetchData = async () => {
    await apis
      .get(url, config)
      .then((res) => {
        setLoading(false);

        if (res.data.success === true) {
          const data = res.data.data; // this is data from api ----- comment below data when using this

          // const data = [
          //   {
          //     retailer_name: "c1",
          //     product_name: ["abc", "def"],
          //     order_id: [1, 2, 3],
          //     retailer_id: "1",
          //     product_id: [1, 2],
          //   },
          //   {
          //     retailer_name: "c2",
          //     product_name: ["abc", "efgh"],
          //     order_id: [2, 3, 4],
          //     retailer_id: "2",
          //     product_id: [1, 4],
          //   },
          // ];
          setData(data);
          setCompanyNameList(() =>
            data.map((val) => ({
              id: val.retailer_id,
              value: val.retailer_name,
            }))
          );

          const uniqueOrderList = data.map((val) => val.order_id).flat();

          const productWithIds = data.flatMap((val) =>
            val.product_name.map((name, index) => ({
              id: val.product_id[index],
              value: name,
            }))
          );
          const uniqueProductNameList = new Map(
            productWithIds.map((product) => [product.id, product])
          );

          setOrderIDList([...new Set(uniqueOrderList)]);
          setProductNameList([...uniqueProductNameList.values()]);
        } else {
          toast.error("Something went wrong. Please try again later.", {
            autoClose: 2000,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.message !== "revoke") {
          toast.error("Something went wrong. Please try again later.", {
            autoClose: 2000,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!!formData.company_name) {
      const Obj = data?.find(
        ({ retailer_id }) => retailer_id == formData.company_name
      );
      setOrderIDList(Obj?.order_id);
      const productWithIds = Obj?.product_name?.map((name, index) => ({
        id: Obj.product_id[index],
        value: name,
      }));
      setProductNameList(productWithIds);
    }
  }, [formData.company_name]);

  useEffect(() => {
    if (!!formData.order_id) {
      const Obj = data?.find(({ order_id }) =>
        order_id.includes(formData.order_id)
      );
      if (Obj) {
        const productWithIds = Obj?.product_name?.map((name, index) => ({
          id: Obj.product_id[index],
          value: name,
        }));
        setProductNameList(productWithIds);
      }
    }
  }, [formData.order_id]);

  useEffect(() => {
    if (!!formData.product_name) {
      const Obj = data?.find(({ product_name }) =>
        product_name.includes(formData.product_name)
      );
      if (Obj) {
        setOrderIDList(Obj?.order_id);
      }
    }
  }, [formData.product_name]);

  const reviewInvoiceHandler = (event) => {
    event.preventDefault();
    setIsOpen(true);
    const postURL = "/invoice/CreateInvoiceData2F";

    const form = event.currentTarget;
    let data = formData;
    data.products = items;

    // form valid ?
    if (form.checkValidity() === true) {
      config.headers.permission = "reports-view";

      apis
        .post(postURL, data, config)
        .then((res) => {
          console.log("response", { res });

          if (res.status === 200) {
            toast.success("Invoice Generated Successfully!", {
              autoClose: 1000,
              position: toast.POSITION.TOP_CENTER,
            });
            navigate("/supplier/invoice_list")
          } else {
            toast.error(res.data.message, {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          console.log({ error });

          if (error) {
            toast.error("Error!.. creating invoice. Please try again later.", {
              autoClose: 3000,
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    }
  };

  const addNextInvoiceHandler = () => {
    setInvoiceNumber((prevNumber) => incrementString(prevNumber));
    setItems([
      {
        id: uid(6),
        name: "",
        qty: 1,
        price: "1.00",
      },
    ]);
  };

  const addItemHandler = () => {
    const id = uid(6);
    setItems((prevItem) => [
      ...prevItem,
      {
        id: id,
        name: "",
        qty: 1,
        price: "1.00",
      },
    ]);
  };

  const deleteItemHandler = (id) => {
    setItems((prevItem) => prevItem.filter((item) => item.id !== id));
  };

  const edtiItemHandler = (event) => {
    const editedItem = {
      id: event.target.id,
      name: event.target.name,
      value: event.target.value,
    };

    const newItems = items.map((items) => {
      for (const key in items) {
        console.log("key check : ", key, editedItem.name);
        if (key === editedItem.name && items.id === editedItem.id) {
          items[key] = editedItem.value;
        }
      }
      return items;
    });

    setItems(newItems);
  };

  const subtotal = items.reduce((prev, curr) => {
    if (curr.name.trim().length > 0)
      return prev + Number(curr.price * Math.floor(curr.qty));
    else return prev;
  }, 0);
  const taxRate = (formData.tax * subtotal) / 100;
  const discountRate = (formData.discount * subtotal) / 100;
  const total = subtotal - discountRate + taxRate;

  // fetch saved form Product Style data from db
  const fetchTaxListData = async () => {
    // add permissions based on URL
    config.headers.permission = "reports-view";
    await apis
      .get(getTaxListData, config)
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          setTaxListData(data);
        }
      })
      .catch((error) => {
        console.log({ error });
        if (error) {
          console.log({ error });
        }
      });
  };
  useEffect(() => {
      fetchTaxListData();
  }, []);

  return (
    <div className="container-fluid page-wrap product-manage invoiceclass">
      <div className="row height-inherit">
        <Sidebar userType={"supplier"} />
        <div className="col main p-0">
          <Header title={t("supplier.invoice_create")} />
          {!!loading && <Loader />}
          {!loading && (
            <div className="container-fluid page-content-box px-3 px-sm-4">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <form
                          className="relative flex flex-col px-2 md:flex-row"
                          onSubmit={reviewInvoiceHandler}
                        >
                          <div className="my-6 flex-1 space-y-2  rounded-md bg-white p-4 shadow-sm sm:space-y-4 md:p-6">
                            <h1 className="text-center text-lg font-bold">
                              {t("supplier.invoice")}
                            </h1>
                            <div className="grid grid-cols-2 gap-2 pt-4 pb-4">
                              <label
                                htmlFor="cashierName"
                                className="text-sm font-bold sm:text-base"
                              >
                                {t("supplier.bill_to")}
                              </label>
                              <select
                                required
                                className="flex-1"
                                placeholder="Customer name"
                                type="text"
                                name="company_name"
                                id="company_name"
                                value={formData.company_name}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    company_name: String(event.target.value),
                                  }))
                                }
                              >
                                <option value="">  {t("supplier.select")}</option>
                                {companyNameList.map((data, index) => {
                                  return (
                                    <option key={index} value={data.id}>
                                      {data.value}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                htmlFor="customerName"
                                className="col-start-2 row-start-1 text-sm font-bold md:text-base"
                              >
                                {t("supplier.invoice_date")}
                              </label>
                              <input
                                required
                                className="flex-1"
                                placeholder="Invoice date"
                                type="date"
                                name="invoice_date"
                                id="invoice_date"
                                value={formData.invoice_date}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    invoice_date: String(event.target.value),
                                  }))
                                }
                              />
                            </div>

                            <div className="grid grid-cols-2 gap-2 pb-4">
                              <label
                                htmlFor="cashierName"
                                className="text-sm font-bold sm:text-base"
                              >
                                {t("supplier.due_date")}
                              </label>
                              <input
                                required
                                className="flex-1"
                                placeholder="Invoice date"
                                type="date"
                                name="due_date"
                                id="due_date"
                                value={formData?.due_date}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    due_date: String(event.target.value),
                                  }))
                                }
                              />
                              <label
                                htmlFor="customerName"
                                className="col-start-2 row-start-1 text-sm font-bold md:text-base"
                              >
                                  {t("supplier.invoice_status")}
                              </label>
                              <select
                                className="flex-1"
                                name="invoice_status"
                                id="invoice_status"
                                value={formData.status}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    status: String(event.target.value),
                                  }))
                                }
                              >
                                <option value="">{t("supplier.select")}</option>
                                <option value="0">Pending</option>
                                <option value="1">Paid</option>
                                <option value="2">Unpaid</option>
                              </select>
                            </div>

                            <div className="grid grid-cols-2 gap-2 pb-4">
                              <label
                                htmlFor="cashierName"
                                className="text-sm font-bold sm:text-base"
                              >
                                &nbsp;
                              </label>
                              <input
                                style={{ backgroundColor: "#fff" }}
                                required
                                className="flex-1"
                                type="text"
                                disabled
                              />
                              <label
                                htmlFor="customerName"
                                className="col-start-2 row-start-1 text-sm font-bold md:text-base"
                              >
                                  {t("supplier.order")}
                              </label>
                              <select
                                className="flex-1"
                                name="order_id"
                                id="order_id"
                                value={formData.order_id}
                                onChange={(event) =>
                                  setFormData((prev) => ({
                                    ...prev,
                                    order_id: String(event.target.value),
                                  }))
                                }
                              >
                                <option value="">{t("supplier.select")}.</option>
                                {orderIDList.map((data, index) => {
                                  return (
                                    <option key={index} value={data}>
                                      {data}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <table className="w-full p-4 text-left">
                              <thead>
                                <tr className="border-b border-gray-900/10 text-sm md:text-base">
                                  <th>{t("supplier.products")}</th>
                                  <th>{t("supplier.qty")}</th>
                                  <th className="text-center"> {t("supplier.price")}</th>
                                  <th className="text-center">  {t("supplier.action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((item, i) => (
                                  <InvoiceItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    qty={item.qty}
                                    price={item.price}
                                    onDeleteItem={deleteItemHandler}
                                    onEdtiItem={edtiItemHandler}
                                    options={
                                      <>
                                        <option value="">  {t("supplier.select")}</option>
                                        {productNameList.map((data, index) => {
                                          return (
                                            <option key={index} value={data.value}>
                                              {data.value}
                                            </option>
                                          );
                                        })}
                                      </>
                                    }
                                  />
                                ))}
                              </tbody>
                            </table>
                            <button
                              className="rounded-md bg-blue-500 px-4 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
                              type="button"
                              onClick={addItemHandler}
                            >
                              {t("supplier.add_item")}
                            </button>
                            <div className="flex flex-col items-end">
                              <div className="basis-1/2 bg-transparent">
                                <div className="sticky top-0 z-10 space-y-4 divide-y divide-gray-900/10 pb-8 md:pl-4">
                                  <div className="space-y-4 py-2">
                                    <div className="space-y-2">
                                      <label
                                        className="text-sm font-bold md:text-base"
                                        htmlFor="tax"
                                      >
                                          {t("supplier.tax_rate")}:
                                      </label>
                                      <div className="flex items-center">
                              {/*          <input
                                          className="w-full rounded-r-none bg-white shadow-sm"
                                          type="number"
                                          name="tax"
                                          id="tax"
                                          min="0.01"
                                          step="0.01"
                                          placeholder="0.0"
                                          value={formData.tax}
                                          onChange={(event) =>
                                            setFormData((prev) => ({
                                              ...prev,
                                              tax: String(event.target.value),
                                            }))
                                          }
                                        /> */}
                                        <select
                                          className="flex-1"
                                          isMulti
                                          name="tax"
                                          id="tax"
                                          value={formData.tax}
                                          onChange={(event) =>
                                            setFormData((prev) => ({
                                              ...prev,
                                              tax: String(event.target.value),
                                            }))
                                          }
                                        >

                                          <option value="">  {t("supplier.select")}</option>
                                          {fullTaxListData &&
                                            fullTaxListData?.map((values) => (
                                              <option value={values?.tax}>
                                                {values?.name}
                                              </option>
                                            ))}
                                        </select>
                                        <span className="rounded-r-md bg-gray-200 py-2 px-4 text-gray-500 shadow-sm">
                                          %
                                        </span>
                                      </div>
                                    </div>
                                    <div className="space-y-2">
                                      <label
                                        className="text-sm font-bold md:text-base"
                                        htmlFor="discount"
                                      >
                                          {t("supplier.discount")}:
                                      </label>
                                      <div className="flex items-center">
                                        <input
                                          className="w-full rounded-r-none bg-white shadow-sm"
                                          type="number"
                                          name="discount"
                                          id="discount"
                                          min="0"
                                          step="0.01"
                                          placeholder="0.0"
                                          value={formData.discount}
                                          onChange={(event) =>
                                            setFormData((prev) => ({
                                              ...prev,
                                              discount: String(
                                                event.target.value
                                              ),
                                            }))
                                          }
                                        />
                                        <span className="rounded-r-md bg-gray-200 py-2 px-4 text-gray-500 shadow-sm">
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="flex w-full justify-between md:w-1/2">
                                <span className="font-bold">{t("supplier.sub_total")}:</span>
                                <span>${subtotal.toFixed(2)}</span>
                              </div>
                              <div className="flex w-full justify-between md:w-1/2">
                                <span className="font-bold"> {t("supplier.discount")}:</span>
                                <span>
                                  ({discount || "0"}%)${discountRate.toFixed(2)}
                                </span>
                              </div>
                              <div className="flex w-full justify-between md:w-1/2">
                                <span className="font-bold">  {t("supplier.tax")}:</span>
                                <span>
                                  ({tax || "0"}%)${taxRate.toFixed(2)}
                                </span>
                              </div>
                              <div className="flex w-full justify-between border-t border-gray-900/10 pt-2 md:w-1/2">
                                <span className="font-bold">{t("supplier.total_amount")}:</span>
                                <span className="font-bold">
                                  ${total % 1 === 0 ? total : total.toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <div>   {t("supplier.note")}:
                            <hr />
<textarea id="note" name="note" rows="3"
value={formData.note}
onChange={(event) =>
setFormData((prev) => ({...prev,note: String(event.target.value),
                                  }))
                                }>

            </textarea>
                            </div>
                            <br />
                            <button
                              className="w-full rounded-md bg-blue-500 py-2 text-sm text-white shadow-sm hover:bg-blue-600"
                              type="submit"
                            >
                              {t("supplier.invoice_create")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
