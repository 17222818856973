import React from "react";

function BillDetails({ formData, setFormData, items }) {
  const allItems = Object.values(items).flat();

  const subtotal = allItems.reduce((prev, curr) => {
    if (curr.name.trim().length > 0)
      return prev + Number(curr.price * Math.floor(curr.qty));
    else return prev;
  }, 0);
  const taxRate = (formData.tax * subtotal) / 100;
  const discountRate = (formData.discount * subtotal) / 100;
  const total = subtotal - discountRate + taxRate;

  return (
    <>
      <div className="flex flex-col items-end">
        <div className="basis-1/2 bg-transparent">
          <div className="sticky top-0 z-10 space-y-4 divide-y divide-gray-900/10 pb-8 md:pl-4">
            <div className="space-y-4 py-2">
              <div className="space-y-2">
                <label className="text-sm font-bold md:text-base" htmlFor="tax">
                  Tax rate:
                </label>
                <div className="flex items-center">
                  <select
                    className="flex-1"
                    isMulti
                    name="tax"
                    id="tax"
                    value={formData.tax}
                    onChange={(event) =>
                      setFormData((prev) => ({
                        ...prev,
                        tax: String(event.target.value),
                      }))
                    }
                  >
                    <option value="">Select...</option>

                    <option value="5">GST 5%</option>
                    <option value="6">GST 6%</option>
                  </select>
                  <span className="rounded-r-md bg-gray-200 py-2 px-4 text-gray-500 shadow-sm">
                    %
                  </span>
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-sm font-bold md:text-base"
                  htmlFor="discount"
                >
                  Discount rate:
                </label>
                <div className="flex items-center">
                  <input
                    className="w-full rounded-r-none bg-white shadow-sm"
                    type="number"
                    name="discount"
                    id="discount"
                    min="0"
                    step="0.01"
                    placeholder="0.0"
                    value={formData.discount}
                    onChange={(event) =>
                      setFormData((prev) => ({
                        ...prev,
                        discount: String(event.target.value),
                      }))
                    }
                  />
                  <span className="rounded-r-md bg-gray-200 py-2 px-4 text-gray-500 shadow-sm">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between md:w-1/2">
          <span className="font-bold">Subtotal:</span>
          <span>${subtotal.toFixed(2)}</span>
        </div>
        <div className="flex w-full justify-between md:w-1/2">
          <span className="font-bold">Discount:</span>
          <span>
            ({formData.discount || "0"}%)${discountRate.toFixed(2)}
          </span>
        </div>
        <div className="flex w-full justify-between md:w-1/2">
          <span className="font-bold">Tax:</span>
          <span>
            ({formData.tax || "0"}%)${taxRate.toFixed(2)}
          </span>
        </div>
        <div className="flex w-full justify-between border-t border-gray-900/10 pt-2 md:w-1/2">
          <span className="font-bold">Total:</span>
          <span className="font-bold">
            ${total % 1 === 0 ? total : total.toFixed(2)}
          </span>
        </div>
      </div>
      <div>
        {" "}
        Note:
        <hr />
        <textarea
          id="note"
          name="note"
          rows="3"
          value={formData.note}
          onChange={(event) =>
            setFormData((prev) => ({
              ...prev,
              note: String(event.target.value),
            }))
          }
        ></textarea>
      </div>
    </>
  );
}

export default BillDetails;
